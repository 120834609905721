import React from "react"
import Slider from "react-slick"

import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const NewsCardSliderItem = ({ item }) => {
  return (
    <Link className="relative block overflow-hidden group" link={item.link}>
      <div className="aspect-[2/3]">
        <Image
          className="object-cover w-full h-full"
          aspectRatio="2by3"
          image={item.image}
        />
      </div>
      <div className="absolute inset-0 transition-opacity duration-500 ease-in-out bg-black opacity-0 bg-opacity-30 group-hover:opacity-100"></div>
      <div className="absolute inset-x-0 bottom-0 transition-transform duration-500 ease-in-out transform translate-y-full group-hover:translate-y-0 ">
        <p className="px-4 prose text-white py-2.5">{item.text}</p>
      </div>
    </Link>
  )
}

const NewsCardSliderBlock = ({ block }) => {
  if (block.bg_color === true) {
    block.block_bg = "bg-roh-light-gray"
  }

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 672,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <BlockWrapper block={block} showHeadline="false">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full lg:w-1/3 px-grid">
          <div className="flex flex-col justify-center h-full">
            <HeadlineEnhanced kicker={block.kicker} headline={block.headline} />
            <Richtext className="mt-8 mb-5 lg:mb-0" text={block.text} />
          </div>
        </div>
        <div className="w-full lg:w-2/3 px-grid">
          <Slider {...settings} className="-mx-2.5">
            {block.news_card_slider_items?.length > 0 &&
              block.news_card_slider_items.map((item, index) => (
                <div className="py-2.5 px-half-grid" key={index}>
                  <NewsCardSliderItem item={item} />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default NewsCardSliderBlock
